.MuiSpeedDialAction-staticTooltipLabel {
    width: 150px;
}

.table-cell-header-dep {
  font-weight: 800 !important;
  background-color: #008A93;
  color: white !important
}

.table-cell-header {
  font-weight: 800 !important;
  background-color: #003643;
  color: white !important
}
.table-cell-header-dep {
  font-weight: 800 !important;
  background-color: #008A93;
  color: white !important
}

.table-cell-header-dep {
  font-weight: 800 !important;
  background-color: #008A93;
  color: white !important
}

.table-cell-header-dep {
  font-weight: 800 !important;
  background-color: #008A93;
  color: white !important
}

/* For screens narrower than 600px (mobile): */
@media only screen and (max-width: 600px) {
  .desktop {
      display: none !important; /* Hide desktop div on mobile */
      -webkit-text-decoration: dashed;
              text-decoration: dashed;
  }
  .mobile {
      /* Mobile styles go here */
      display: block !important; /* Hide desktop div on mobile */
  }
}

@media only screen and (min-width: 600px) {
  .desktop {
      display: block !important; /* Hide desktop div on mobile */
  }
  .mobile {
      /* Mobile styles go here */
      display: none !important; /* Hide desktop div on mobile */
  }
}
/* For screens narrower than 600px (mobile): */
@media only screen and (max-width: 600px) {
  .desktop {
      display: none !important; /* Hide desktop div on mobile */
      -webkit-text-decoration: dashed;
              text-decoration: dashed;
  }
  .mobile {
      /* Mobile styles go here */
      display: block !important; /* Hide desktop div on mobile */
  }
}

@media only screen and (min-width: 600px) {
  .desktop {
      display: block !important; /* Hide desktop div on mobile */
  }
  .mobile {
      /* Mobile styles go here */
      display: none !important; /* Hide desktop div on mobile */
  }
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  color: #032a37 ;
  opacity: 1;
}


.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  color: #032a37 ;
  opacity: 1;
}


.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  color: #032a37 ;
  opacity: 1;
}

.centre {
    margin-top: 25%;
    margin-left: 25%;
}

/* For screens narrower than 600px (mobile): */
@media only screen and (max-width: 600px) {
  .desktop {
      display: none !important; /* Hide desktop div on mobile */
      -webkit-text-decoration: dashed;
              text-decoration: dashed;
  }
  .mobile {
      /* Mobile styles go here */
      display: block !important; /* Hide desktop div on mobile */
  }
}

@media only screen and (min-width: 600px) {
  .desktop {
      display: block !important; /* Hide desktop div on mobile */
  }
  .mobile {
      /* Mobile styles go here */
      display: none !important; /* Hide desktop div on mobile */
  }
}
/* For screens narrower than 600px (mobile): */
@media only screen and (max-width: 600px) {
  .desktop {
      display: none !important; /* Hide desktop div on mobile */
      -webkit-text-decoration: dashed;
              text-decoration: dashed;
  }
  .mobile {
      /* Mobile styles go here */
      display: block !important; /* Hide desktop div on mobile */
  }
}

@media only screen and (min-width: 600px) {
  .desktop {
      display: block !important; /* Hide desktop div on mobile */
  }
  .mobile {
      /* Mobile styles go here */
      display: none !important; /* Hide desktop div on mobile */
  }
}
.pill {
  background-color: #e6e6e6;
  border-radius: 10px;
  color: #ff834f;
  margin-top: 3%;
  height: 8%;
}
.pill-text {
  padding: 5px;
  margin-left: 7%;
  display: inline-block;
}
html {
  font-size: 100%;
}
.search-results-found {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: #FFFFFF;
}

.searchresultstable {
  width: "100%";
}

.search-table {
  background: #FFFFFF;
  border: 1px solid #A7A7A7;
  margin: 30px 0;
  width: 100%;
}

.search-table tr {
  border-top: 1px solid #A7A7A7;
}

.search-table tr th {
  color: #000000;
  padding: 10px;
  font-size: 12px;
  font-style: normal;
  font-family: Lato;
  font-weight: 700;
  line-height: 15px;
  border-right: 1px solid #A7A7A7;
}

.search-table tr td {
  color: #000000;
  padding: 10px;
  font-size: 12px;
  font-style: normal;
  font-family: open sans;
  font-weight: 400;
  line-height: 15px;
  border-right: 1px solid #A7A7A7;
}
.search-table tr td  button{
  color: #fff !important;
  padding: 10px;
  font-size: 12px;
  background-color: #fba92c !important;
  box-shadow: none;
  font-style: normal;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 15px;
  border-radius: 8px;
  text-transform: capitalize;
  width: 70px;
  height: 30px;
}
/* For screens narrower than 600px (mobile): */
@media only screen and (max-width: 600px) {
  .desktop {
      display: none !important; /* Hide desktop div on mobile */
      -webkit-text-decoration: dashed;
              text-decoration: dashed;
  }
  .mobile {
      /* Mobile styles go here */
      display: block !important; /* Hide desktop div on mobile */
  }
}

@media only screen and (min-width: 600px) {
  .desktop {
      display: block !important; /* Hide desktop div on mobile */
  }
  .mobile {
      /* Mobile styles go here */
      display: none !important; /* Hide desktop div on mobile */
  }
}
/* For screens narrower than 600px (mobile): */
@media only screen and (max-width: 600px) {
  .desktop {
      display: none !important; /* Hide desktop div on mobile */
      -webkit-text-decoration: dashed;
              text-decoration: dashed;
  }
  .mobile {
      /* Mobile styles go here */
      display: block !important; /* Hide desktop div on mobile */
  }
}

@media only screen and (min-width: 600px) {
  .desktop {
      display: block !important; /* Hide desktop div on mobile */
  }
  .mobile {
      /* Mobile styles go here */
      display: none !important; /* Hide desktop div on mobile */
  }
}
.table-cell-header {
  font-weight: 800 !important;
  background-color: #003643;
  color: white !important
}
.marginTop15 {
  margin-top: 15px;
}
.MuiGrid-item-33.marginTop15 {
  margin-top: 15px;
}
.error {
  color: red;
}
.success {
  color: green;
}

.table-cell-header {
  font-weight: 800 !important;
  background-color: #003643;
  color: white !important
}
.table-cell-header {
  font-weight: 800 !important;
  background-color: #003643;
  color: white !important
}
.table-cell-header {
  font-weight: 800 !important;
  background-color: #003643;
  color: white !important
}
.table-cell-header {
  font-weight: 800 !important;
  background-color: #003643;
  color: white !important
}
.table-cell-header {
  font-weight: 800 !important;
  background-color: #003643;
  color: white !important
}
.table-cell-header {
  font-weight: 800 !important;
  background-color: #003643;
  color: white !important
}
.table-cell-header {
  font-weight: 800 !important;
  background-color: #003643;
  color: white !important
}
.table-cell-header {
  font-weight: 800 !important;
  background-color: #003643;
  color: white !important
}
.speech-bubble {
	position: relative;
	background: #e6e6e6;
    border-radius: .4em;
    padding: 5px;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	right: 0;
	top: 65%;
	width: 0;
	height: 0;
	border: 0.625em solid transparent;
	border-left-color: #e6e6e6;
	border-right: 0;
	border-bottom: 0;
	margin-right: -0.625em;
}

.panel{
	margin: auto;
	background-color: #ddd;
}

  .language-circle{
    width:20px;
    height:20px;
    border-radius:20px;
    font-size:12px;
    color:#fff;
    line-height:20px;
    text-align:center;
    background:#FF8349
}

  textarea {
	width: 100%;
	height: 90px;
	padding: 12px 20px;
	box-sizing: border-box;
	border: 2px solid #ccc;
	border-radius: 4px;
	background-color: #fff;
	font-size: 16px;
	resize: both;
  }
  
  #code {
	width: 100%;
	height: 100%;
	padding: 12px 20px;
	box-sizing: border-box;
	border: 2px solid #ccc;
	border-radius: 4px;
	background-color: #fff;
	font-size: 16px;
	resize: none;
  }
.number{
  width: 15%;
  /* padding: 12px 20px; */
  margin: 0px 26px;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid grey !important;
  background-color: inherit;
  color: blue;
}
.hero-image {
  background-image: url([object Module]);
  background-color: #fff;
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
  
.header-section{
  padding-top:130px;
  overflow: hidden;
}

.gridItem1{
  position: relative;
  padding-right: 3%;
  padding-left: 3%;
  margin-bottom: 1.5rem!important;  
}

.grid-item-margin{
  margin: auto;
}

.p1{
    display: block;
    text-transform: uppercase!important;
    font-size: 16px;
    color: #fff;
    text-align: left;
    font-weight: 400;
    line-height: 1.5;
}

.p2{
    line-height: 1.8;
    font-size: 15px;
}
.title{
    font-size: 32px;
    font-weight: 500
}

.hero-image {
  background-image: url([object Module]);
  background-color: #fff;
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
  
.header-section{
  padding-top:130px;
  overflow: hidden;
}

.gridItem1{
  position: relative;
  padding-right: 3%;
  padding-left: 3%;
  margin-bottom: 1.5rem!important;  
}

.grid-item-margin{
  margin: auto;
}

.p1{
    display: block;
    text-transform: uppercase!important;
    font-size: 16px;
    color: #fff;
    text-align: left;
    font-weight: 400;
    line-height: 1.5;
}

.p2{
    line-height: 1.8;
    font-size: 15px;
}
.title{
    font-size: 32px;
    font-weight: 500
}

.table-cell-header {
  font-weight: 800 !important;
  background-color: #003643;
  color: white !important
}
.table-cell-header {
  font-weight: 800 !important;
  background-color: #003643;
  color: white !important
}
